var middelburg_Report = { // Object containing the compile_report module.
  addPageToReport: function() { // Add section to cookie. Add to my report button in page options bar.
    var thisPageId = document.body.getAttribute("data-nodeinfo");
    var myReportData = this.splitCookie();

    var nodeAlreadyInReport = false;

    for (var i = 0; i < myReportData.length; i++) {
      if (myReportData[i] == thisPageId) {
        nodeAlreadyInReport = true;
      }
    }

    if (nodeAlreadyInReport == false) {
      myReportData.push(thisPageId);
    }

    myReportData = myReportData.join("&");

    var checkboxesCookie = "checkboxesCookie=" + myReportData + ";path=/;";
    document.cookie = checkboxesCookie;

    return false;
  },
  clearSelection: function() {
    var checkboxes = this.getCheckboxInputs();

    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }

    this.createCheckboxesCookie();
    this.generateURL();

    return false;
  },
  createCheckboxesCookie: function() { // Creates the cookie to remember the user's selection between pages.
    var checkboxInputsArray = this.getSectionIds();
    var checkboxInputsString = checkboxInputsArray.join("&");

    var checkboxesCookie = "checkboxesCookie=" + checkboxInputsString + ";path=/;";
    document.cookie = checkboxesCookie;
  },
  generateURL: function(nodeid) {
    var sectionIds = this.getSectionIds();
/*    var url = '/pdfondemand/printpdf?docId=';*/
    var url = podUrl;

    url += document.getElementById("my-report-ul").dataset.documentid;

    if (sectionIds.length > 0) {
      url += "&nodes=" + sectionIds.join("-");
    }

    checkboxInputs = this.getCheckboxInputs();
    var thisCheckbox;

    for (var i = 0; i < checkboxInputs.length; i++) {
      if (checkboxInputs[i].dataset.nodeinfo == nodeid) {
        thisCheckbox = checkboxInputs[i];
      }
    }

    document.getElementById("download-own-selection").setAttribute("href", url);
    this.createCheckboxesCookie();
  },
  getCheckboxesCookie: function() { // Retrieves the checkboxesCookie and sets the user's previous selection choice.
    var cookieData = this.splitCookie();


    for (var indexCookie = 0; indexCookie < cookieData.length; indexCookie++) {
      var checkboxes = this.getCheckboxInputs();
      for (var indexCheckbox = 0; indexCheckbox < checkboxes.length; indexCheckbox ++) {
        if (cookieData[indexCookie] == checkboxes[indexCheckbox].dataset.nodeinfo) {
          checkboxes[indexCheckbox].checked = true;
        }
      }
    }

    this.generateURL();
  },
  getCheckboxInputs: function() { // Creates an array of all the checkbox inputs on the page. Returns this array.
    var checkboxes = document.getElementsByTagName("input");
    var checkboxesMyReport = [];

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].className == "compile-report-checkbox") {
        checkboxesMyReport.push(checkboxes[i]);
      }
    }

    return checkboxesMyReport;
  },
  getSectionIds: function() { // Gets the node IDs from the checkbox data attributes.
    var tickedCheckboxInputs = this.getTickedCheckboxInputs();
    var sectionIds = [];

    for (var i = 0; i < tickedCheckboxInputs.length; i++) {
      sectionIds.push(tickedCheckboxInputs[i].dataset.nodeinfo);
    }
    return sectionIds;
  },
  splitCookie: function() { // Function to split up the cookie data into an array.
    var cookie = document.cookie;
    var cookieArray = cookie.split(";");
    var cookieData = [];
    var regExp = /(\s)?checkboxesCookie=/gi;

    for (var i = 0; i < cookieArray.length; i++) {
      if (regExp.test(cookieArray[i])) {
        var cookieSplit = cookieArray[i].split("=");
        cookieData = cookieSplit[1].split("&");
      }
    }

    return cookieData;
  },
  getTickedCheckboxInputs: function() { // Creates an array of all the ticked checkbox inputs on the page. Returns this array.
    var checkboxInputs = this.getCheckboxInputs();
    var tickedCheckboxInputs = [];

    for (var i = 0; i < checkboxInputs.length; i++) {
      if (checkboxInputs[i].checked == true) {
        tickedCheckboxInputs.push(checkboxInputs[i]);
      }
    }

    return tickedCheckboxInputs;
  }
}
